/**
 *
 * @param {Date} date
 * @returns {string} yyyy-mm-dd
 */
export function formatDate(date) {
  if (!date) return null
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export function parseDateFromDayString(dateStr) {
  if (!/^\d{2}\.\d{2}\.\d{4}$/.test(dateStr)) return null

  const [day, month, year] = dateStr.split('.').map(Number)

  if (day < 1 || day > 31 || month < 1 || month > 12) return null

  return new Date(year, month - 1, day)
}

<template>
  <input
    ref="input"
    class="el-input__inner"
    v-mask="mask"
    v-bind="$props"
    v-model="inputValue"
  />
</template>

<script>
import { mask } from 'vue-the-mask'
const InputMask = (el, binding) => {
  if (!binding.value) return
  const tokens = el.__vue__?.tokens
  return tokens
    ? mask(el, {
        value: {
          mask: binding.value,
          tokens,
        },
      })
    : mask(el, binding)
}

export default {
  props: ['value', 'mask', 'tokens', 'disabled'],
  directives: { mask: InputMask },
  data() {
    const inputValue = this.value
    return {
      inputValue,
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue(value) {
      this.$emit('input', value)
      this.$refs.input?.parentElement?.parentElement?.__vue__?.validate()
    },
  },
}
</script>

<style lang="scss">
.el-input__inner[disabled] {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>

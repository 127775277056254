<template>
  <div id="login-popup">
    <PopupBase :noHeader="true" :show="show" @close="show = false">
      <Login
        ref="login"
        @close="show = false"
        :noConfirm="noConfirm"
        :customTexts="texts"
        :customPhone="phone"
        :phoneOnlyCountries="phoneOnlyCountries"
        :dontSendCustomerId="dontSendCustomerId"
      />
    </PopupBase>
  </div>
</template>

<script>
import Login from '@/views/Login'
import PopupBase from '@/components/Popups/PopupBase'

export default {
  components: { Login, PopupBase },
  data() {
    return {
      show: false,
      noConfirm: false,
      texts: null,
      phone: null,
      callback: null,
      phoneOnlyCountries: null,
      dontSendCustomerId: false,
    }
  },
  events: {
    'do-login-popup'(options = {}) {
      this.show = true
      this.noConfirm = options.noConfirm
      this.texts = options.texts || null
      this.phone = options.phone || null
      this.callback = options.callback || null
      this.phoneOnlyCountries = options.phoneOnlyCountries || null
      this.dontSendCustomerId = options.dontSendCustomerId || false
      this.$events.emit('log-event', 'try_authenticate')
    },
    async authorized() {
      this.show = false
      await this.$waitFor(() => !this.$store.navigationBlockers.loginPopup)
      if (this.callback) this.callback()
      this.callback = null
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.$store.navigationBlockers.loginPopup = value
          ? () => {
              this.$nextTick(() => {
                this.show = false
              })
            }
          : null
      },
    },
  },
}
</script>

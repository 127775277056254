<template>
  <div id="login">
    <div class="title">
      {{ titleText }}
    </div>
    <div class="subtitle" v-html="descriptionText" />

    <VuePhoneNumberInput
      class="phone-number"
      v-model="phoneNumberValue"
      v-if="!waitingCode"
      ref="phoneNumber"
      :no-example="true"
      @update="data => (phoneNumberData = data)"
      :translations="$t('auth.phoneNumberInput')"
      :defaultCountryCode="
        phoneOnlyCountries ? undefined : $t('countryCode').toUpperCase()
      "
      :only-countries="phoneOnlyCountries"
    />

    <div class="change-phone" @click="changePhone" v-if="waitingCode">
      {{ $t('auth.changePhone') }}
    </div>

    <PincodeInput v-model="code" v-if="waitingCode" />

    <div class="error" v-if="error" v-html="errorMsg" v-html-reactive-links />

    <div class="resend-label" v-if="waitingCode && timeout > 0">
      {{ $t('auth.resendWaiting', [timeout]) }}
    </div>

    <div class="buttons">
      <div class="cancel" @click="$emit('close')">
        {{ $t('settings.cancel') }}
      </div>

      <div class="send-code" v-if="!waitingCode" @click="start">
        {{ $t('next') }}
      </div>

      <div
        class="resend"
        :class="{ active: timeout == 0 }"
        v-if="waitingCode"
        @click="timeout == 0 ? start() : null"
      >
        {{ $t('auth.resend') }}
      </div>
    </div>

    <div class="legal-label" v-if="!waitingCode && !$store.overrideToken">
      <span>{{ $t('auth.legalLabel.0') }}</span>
      <a href="https://partner.cncrg.org/user-agreement" target="_blank">{{
        $t('auth.legalLabel.1')
      }}</a>
      <span>{{ $t('auth.legalLabel.2') }}</span>
      <a
        href="https://partner.cncrg.org/personal-data-agreement"
        target="_blank"
        >{{ $t('auth.legalLabel.3') }}</a
      >
    </div>
  </div>
</template>

<script>
import PincodeInput from 'vue-pincode-input'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import phoneNumberExamples from 'libphonenumber-js/examples.mobile.json'
import { getExampleNumber } from 'libphonenumber-js'

export default {
  props: [
    'noConfirm',
    'customTexts',
    'customPhone',
    'dontSendCustomerId',
    'phoneOnlyCountries',
  ],
  components: { VuePhoneNumberInput, PincodeInput },
  data() {
    return {
      phoneNumberValue: null,
      phoneNumberData: null,

      error: false,
      errorMsg: '',

      timeoutInterval: null,
      timeout: 0,

      waitingCode: false,
      code: '',

      texts: {
        enterTitle: 'auth.enterPhone',
        enterDescription: 'auth.subtitlePhone',
        waitingTitle: 'auth.confirmPhone',
        waitingDescription: 'auth.subtitleCode',
        ...(this.customTexts ? this.customTexts : []),
      },
    }
  },
  created() {
    this.timeoutInterval = setInterval(() => {
      if (this.timeout > 0) this.timeout--
    }, 1000)
  },
  mounted() {
    if (localStorage.customerId) {
      this.$api.get('user').then(res => {
        this.phoneNumberValue = res.data.data.phoneNumber
      })
    }
    if (this.customPhone || localStorage.customerId) {
      this.phoneNumberValue = this.customPhone
      const unwatchIsValidated = this.$watch(
        'phoneNumberData.phoneNumber',
        () => {
          unwatchIsValidated()
          this.start()
        },
      )
    }
    this.$refs.phoneNumber.$refs.PhoneNumberInput.$refs.InputTel.onkeydown = ({
      key,
    }) => {
      if (key == 'Enter') this.start()
    }
  },
  beforeDestroy() {
    clearInterval(this.timeoutInterval)
  },
  methods: {
    requestCustomerId() {
      if (!this.phoneNumberData.isValid)
        return this.$events.emit(
          'show-alert',
          this.$t('errors.error'),
          this.$t('auth.errorPhone'),
        )

      this.error = false
      this.$api
        .post(
          'login/start',
          {
            confirm: false,
            phone: this.phoneNumberData.formattedNumber,
          },
          {
            headers: this.dontSendCustomerId
              ? { 'x-customer-id': null }
              : undefined,
          },
        )
        .then(res => {
          const customerId = res.data.data.userId
          localStorage.customerId = customerId
          this.$api.defaults.headers.common['x-customer-id'] = customerId
          this.$events.emit('authorized')
          this.$events.emit('log-event', 'authenticated')
        })
        .catch(err => {
          this.error = true
          this.errorMsg = err.response.data.message || err.message
        })
    },
    sendCode() {
      if (!this.phoneNumberData.isValid)
        return this.$events.emit(
          'show-alert',
          this.$t('errors.error'),
          this.$t('auth.errorPhone'),
        )

      this.error = false

      this.waitingCode = true
      this.$api
        .post(
          `login/start`,
          {
            phone: this.phoneNumberData.formattedNumber,
          },
          {
            headers: this.dontSendCustomerId
              ? { 'x-customer-id': null }
              : undefined,
          },
        )
        .then(() => this.$events.emit('log-event', 'try_identificate'))
        .catch(err => {
          this.waitingCode = false
          this.error = true
          this.errorMsg = err.response.data.message || err.message
        })
    },
    start() {
      this.timeout = 60
      this.noConfirm ? this.requestCustomerId() : this.sendCode()
    },
    login() {
      this.$api
        .post(
          `login`,
          {
            loginId: this.phoneNumberData.formattedNumber,
            smsCode: this.code,
          },
          {
            headers: this.dontSendCustomerId
              ? { 'x-customer-id': null }
              : undefined,
          },
        )
        .then(res => {
          localStorage.removeItem('customerId')
          delete this.$api.defaults.headers.common['x-customer-id']

          localStorage.token = res.data.data.token
          localStorage.refreshToken = res.data.data.refreshToken
          this.$api.defaults.headers.common.Token = localStorage.token

          this.$events.emit('authorized')
          this.$events.emit('log-event', 'identificated')
          this.$events.emit('show-pwa-popup')

          this.waitingCode = false
          this.code = ''
        })
        .catch(err => {
          this.error = true
          this.errorMsg = err.response.data.message || err.message
        })
    },
    changePhone() {
      this.error = false
      localStorage.removeItem('customerId')
      delete this.$api.defaults.headers.common['x-customer-id']
      this.phoneNumberValue = null
      this.waitingCode = false
    },
  },
  watch: {
    phoneNumberValue() {
      if (!this.phoneNumberData?.formattedNumber) return
      const { formattedNumber } = this.phoneNumberData
      if (formattedNumber.length > this.maxPhoneNumberLength) {
        this.phoneNumberValue = formattedNumber.slice(
          0,
          this.maxPhoneNumberLength,
        )
      }
    },
    code(value) {
      if (value.length >= 4) this.login()
    },
  },
  computed: {
    maxPhoneNumberLength() {
      return getExampleNumber(
        this.phoneNumberData.countryCode,
        phoneNumberExamples,
      ).number.length
    },
    titleText() {
      return this.waitingCode
        ? this.$t(this.texts.waitingTitle)
        : this.$t(this.texts.enterTitle)
    },
    descriptionText() {
      return this.waitingCode
        ? this.$t(this.texts.waitingDescription, [
            this.phoneNumberData?.formatInternational,
          ])
        : this.$t(this.texts.enterDescription)
    },
  },
}
</script>

<style lang="scss">
#login {
  padding: 15px;
  max-width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 5px;
    align-self: flex-start;
  }

  > .subtitle {
    align-self: flex-start;
  }

  > .phone-number {
    margin-top: 10px;
    align-self: flex-start;
    max-width: 96%;
  }

  > .vue-pincode-input-wrapper {
    margin: 20px 0;

    input.vue-pincode-input {
      box-shadow: none;
      border-radius: 0;
      box-shadow: none;
      border-bottom: 2px #47525e solid;
    }
  }

  .change-phone {
    margin: 10px 0;
    text-decoration: underline;
    cursor: pointer;
    align-self: flex-start;
  }

  > .error {
    color: red;
    margin: 15px 0;
  }

  .resend-label {
    font-size: 14px;
    font-weight: 400;
    transition: 0.2s color;
    margin-bottom: 10px;
    text-align: center;
  }

  > .buttons {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > * {
      margin-right: 15px;
      cursor: pointer;
      font-size: 15px;
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      transition: 0.25s all;
      padding: 6px 10px;
      border-radius: 4px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    > .cancel {
      font-weight: 400;
    }

    .resend {
      cursor: default;
      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }

      &.active {
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  > .legal-label {
    margin-top: 25px;
    font-size: 12px;
  }
}
</style>

<script>
import Vue from 'vue'

export default {
  methods: {
    logEvent(event, payload = {}, addUserId = true) {
      let hasUser = !!this.$store.user

      const post = user => {
        if (user) {
          payload = { ...payload, user_id: user.userId }
        }

        if (!this.$store.isIframe) console.log('[event]', event, payload)
        Vue.firebase.analytics().logEvent(event, payload)
        this.$events.emit('post-iframe', event, payload)
        for (const hotelYa of [87200352, this.$store.hotelYa].filter(Boolean)) {
          window.ym(hotelYa, 'reachGoal', event, { params: payload })
        }
      }

      if (hasUser && addUserId) {
        post(this.$store.user)
      } else post()
    },
  },
}
</script>
